import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAccount,
  IAccountLoginBody,
  IAccountRegisterBody,
  ILoginAccount,
} from '@models/account';
import { ResetPasswordModel } from '@shared/models/reset-password.model';
import { SuggestAccountModel } from '@shared/models/suggest-account.model';
import _pick from 'lodash-es/pick';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(private http: HttpClient) {}

  getAccount() {
    return this.http.get<IAccount>('account');
  }

  login(model: IAccountLoginBody) {
    let params = new HttpParams();
    params = params.set('isWebsiteAccount', model.IsWebsiteAccount.toString());

    return this.http.post<ILoginAccount>(
      'account/login',
      _pick(model, ['Email', 'Password']),
      { params: params }
    );
  }

  register(user: IAccountRegisterBody) {
    return this.http.post<ILoginAccount>('account/register', user);
  }

  resetPassword(userData: ResetPasswordModel) {
    return this.http.post('account/resetpassword', userData);
  }

  forgotPassword(Email: string) {
    return this.http.post('account/forgotpassword', { Email });
  }

  suggestAccount() {
    return this.http.get<SuggestAccountModel>('account/suggest');
  }

  deleteUser() {
    return this.http.delete<void>('account?confirm=true');
  }

  loginViaProvider(form: {
    provider: string;
    providerId;
    Email: string;
    FirstName: string;
    LastName: string;
    AvatarUrl: string;
  }) {
    const { provider, providerId, Email, FirstName, LastName, AvatarUrl } =
      form;
    return this.http.post<ILoginAccount>(`account/login/${provider}`, {
      Id: providerId,
      FirstName,
      Email,
      LastName,
      AvatarUrl,
    });
  }

  logout() {
    return this.http.post<void>('account/logout', {});
  }

  uploadProfilePicture(image: Blob) {
    const formData = new FormData();
    formData.append('file', image);
    return this.http.post<string>('images/useravatar', formData);
  }

  changeProfilePicture(imageUrl?: string) {
    return this.http.post<IAccount>(
      'account/avatar',
      imageUrl ? _prepareStringAsPayload(imageUrl) : {},
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      }
    );
  }

  update(
    form:
      | Partial<{
          Email: string;
          FirstName: string;
          LastName: string;
          Nickname: string;
          AvatarUrl: string;
          Bio: string;
          Location: string;
        }>
      | Partial<{
          Password: string;
          NewPassword: string;
          NewPasswordConfirmation: string;
        }>
  ) {
    return this.http.post<IAccount>('account/update', form);
  }
}

const _prepareStringAsPayload = (text) => {
  return '"' + ('' + text).replace(/"/g, '\\"') + '"';
};
