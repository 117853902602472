import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AlertController,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSegment,
  IonSegmentButton,
  Platform,
} from '@ionic/angular/standalone';
import { RegisterModal } from '@modals/register.modal.component';
import { IState } from '@models';
import { Store } from '@ngrx/store';
import { AccountService } from '@services/account.service';
import { ModalService } from '@services/modal.service';
import { ToastService } from '@services/toast.service';
import { AutofillDirective } from '@shared/directives/app-autofill.directive';
import { UserLoginModel } from '@shared/models/user-login.model';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  FacebookAuthService,
  FBUser,
} from '@shared/services/auth/facebook-auth.service';
import { LoaderService } from '@shared/services/loader.service';
import * as userActions from '@store/actions/user';
import { finalize, tap } from 'rxjs';

@Component({
  selector: 'cs-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonSegment,
    IonSegmentButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    AutofillDirective,
  ],
  providers: [AutofillDirective],
  template: `
    <form [formGroup]="formGroup" (ngSubmit)="submit()">
      <ion-segment class="my-2" formControlName="type">
        <ion-segment-button class="ion-text-wrap" value="cigar-scanner">
          Cigar Scanner Account
        </ion-segment-button>
        <ion-segment-button class="ion-text-wrap" value="neptune">
          Neptune Cigar Account
        </ion-segment-button>
      </ion-segment>
      <ion-list lines="full">
        <ion-item>
          <ion-label position="floating">Email</ion-label>
          <ion-input
            appAutofill
            formControlName="email"
            type="email"
            autocomplete="email"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Password</ion-label>
          <ion-input
            appAutofill
            formControlName="password"
            autocomplete="current-password"
            type="password"
          ></ion-input>
          <ion-button
            *ngIf="formGroup.get('type').value === 'cigar-scanner'"
            fill="outline"
            color="primary"
            size="small"
            slot="end"
            (click)="forgot()"
          >
            forgot?
          </ion-button>
        </ion-item>
      </ion-list>
      <div
        class="px-2"
        [ngClass]="{
          'grid grid-cols-2 gap-4':
            formGroup.get('type').value === 'cigar-scanner'
        }"
      >
        <ion-button
          *ngIf="formGroup.get('type').value === 'cigar-scanner'"
          expand="block"
          color="light"
          size="large"
          type="button"
          class="mt-5"
          (click)="register()"
          >Register</ion-button
        >
        <ion-button expand="block" size="large" type="submit" class="mt-5"
          >Sign In</ion-button
        >
      </div>
    </form>

    <div class="relative my-5 ">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div
          class="w-full border-t border-gray-200 dark:border-gray-600 border-solid"
        ></div>
      </div>
      <div class="relative flex justify-center text-sm font-medium leading-6">
        <span
          class="bg-white dark:text-white dark:bg-[var(--ion-background-color)] px-6 text-gray-900"
          >Or continue with</span
        >
      </div>
    </div>

    <div class="ion-margin-top px-2">
      <ion-button
        expand="block"
        size="large"
        color="facebook"
        style="--padding-top: 5px; --padding-bottom: 5px;"
        (click)="facebook()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="50"
          width="50"
          viewBox="-204.79995 -341.33325 1774.9329 2047.9995"
        >
          <path
            d="M1365.333 682.667C1365.333 305.64 1059.693 0 682.667 0 305.64 0 0 305.64 0 682.667c0 340.738 249.641 623.16 576 674.373V880H402.667V682.667H576v-150.4c0-171.094 101.917-265.6 257.853-265.6 74.69 0 152.814 13.333 152.814 13.333v168h-86.083c-84.804 0-111.25 52.623-111.25 106.61v128.057h189.333L948.4 880H789.333v477.04c326.359-51.213 576-333.635 576-674.373"
            fill="#1877f2"
          />
          <path
            d="M948.4 880l30.267-197.333H789.333V554.609C789.333 500.623 815.78 448 900.584 448h86.083V280s-78.124-13.333-152.814-13.333c-155.936 0-257.853 94.506-257.853 265.6v150.4H402.667V880H576v477.04a687.805 687.805 0 00106.667 8.293c36.288 0 71.91-2.84 106.666-8.293V880H948.4"
            fill="#fff"
          />
        </svg>
        Facebook</ion-button
      >
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnChanges {
  @HostBinding('class') class = 'max-w-2xl mx-auto';
  formGroup = this.fb.group({
    type: this.fb.control<'cigar-scanner' | 'neptune'>('cigar-scanner', [
      Validators.required,
    ]),
    email: this.fb.control<string>('', [Validators.required]),
    password: this.fb.control<string>('', [Validators.required]),
  });
  @Input() email: string = '';
  @Input() isNeptune: boolean = false;
  @Input() isFacebook: boolean = false;
  @Output() registerClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() forgotSuccess: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private alertController: AlertController,
    private toastService: ToastService,
    private loader: LoaderService,
    private platform: Platform,
    private facebookAuthService: FacebookAuthService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private analyticsService: AnalyticsService,
    private store: Store<IState>
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.email?.currentValue) {
      this.formGroup.get('email').setValue(changes.email.currentValue);
      this.cdr.detectChanges();
    }
    if (changes.isNeptune?.currentValue) {
      this.formGroup.get('type').setValue('neptune');
      this.cdr.detectChanges();
    }
    if (changes.isFacebook?.currentValue) {
      this.facebook();
    }
  }

  async register() {
    this.registerClick.emit();
    const modal = await this.modalService.createCSModal(RegisterModal, {
      email: this.formGroup.get('email').value,
    });
    await modal.present();
  }

  async forgot() {
    const alert = await this.alertController.create({
      header: 'Forgot Password',
      message: 'Enter your email address',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email',
          value: this.formGroup.get('email').value || '',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Send',
          handler: (data) => {
            this.loader.show(false);
            this.accountService
              .forgotPassword(data.email)
              .pipe(
                tap(() => {
                  this.toastService.show('Please check your email');
                  this.forgotSuccess.emit();
                }),
                finalize(() => this.loader.hide())
              )
              .subscribe();
          },
        },
      ],
    });
    await alert.present();
  }
  async facebook() {
    this.loader.show(false);
    this.analyticsService.login('facebook');

    if (this.platform.is('capacitor')) {
      try {
        const user = await this.facebookAuthService.login();
        await this.facebookSuccess(user);
      } catch (error) {
        this.loader.hide();
      }
    } else {
      this.facebookAuthService.loginWeb(
        this.facebookSuccess.bind(this),
        (error) => {
          this.loader.hide();
        }
      );
    }
  }

  async facebookSuccess(user: FBUser) {
    this.store.dispatch(
      userActions.LoginProviderRequest({
        form: {
          provider: 'facebook',
          providerId: user.id,
          Email: user.email,
          FirstName: user.first_name,
          LastName: user.last_name,
          AvatarUrl: user.picture.data.url,
        },
      })
    );
  }

  submit() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    // this.loader.show(false);
    const { email, password, type } = this.formGroup.value;
    this.analyticsService.login(type);

    const model: UserLoginModel = {
      Email: email,
      Password: password,
      IsWebsiteAccount: type === 'neptune',
    };

    this.store.dispatch(userActions.LoginRequest({ form: model }));
  }
}
