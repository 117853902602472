import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginComponent } from '@components/login.component';
import { ModalService } from '@services/modal.service';
import { AppRoutes } from '@utils/routes';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
} from '@ionic/angular/standalone';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    LoginComponent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <cs-login
        [email]="email"
        [isNeptune]="isNeptune"
        [isFacebook]="isFacebook"
        (forgotSuccess)="dismiss()"
        (registerClick)="dismiss()"
      ></cs-login>
    </ion-content>
  `,
})
export class LoginModal implements OnInit {
  @Input() title = 'This action requires an account';
  @Input() email: string = '';
  @Input() isNeptune: boolean = false;
  @Input() isFacebook: boolean = false;
  constructor(private modalService: ModalService, private router: Router) {
    addIcons({ closeOutline });
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        this.modalService.dismiss();
      });
  }

  dismiss() {
    this.modalService.dismiss();
  }
}
